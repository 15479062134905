import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Table,
  Tag
} from "antd";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Wrapper from "../../components/layout";
import moment from "moment-timezone";
import styled from './styled.module.scss'
import { useForm } from "antd/es/form/Form";
import { useRest } from "../../services/http";

const Reports = () => {

  const [form] = useForm();
  const [filterParams, setFilterParams] = useState({
    service: null,
    status: null,
    out_of_time: null,
    finished: null,
  });
  const [clients, setClients] = useState();
  const [status, setStatus] = useState();
  const [dataReport, setDataReport] = useState();
  const [dinamicColums, setDinamicColums] = useState();

  const {
    get: flowsList,
    loading: flowListLoading,
    data: flowsData,
  } = useRest();

  const { get: serviceList, data: serviceData } = useRest();
  const { get: getStatus, data: dataStatus, status: statusStatus } = useRest();

  useEffect(() => {
    getStatus("statusFlow", {});
  }, []);

  useEffect(() => {
    serviceList("serviceList", {});
  }, []);

  useEffect(() => {
    flowsList("flowsList", {});
  }, []);

  useEffect(() => {
    if (statusStatus?.success) {
      let arr = Object.keys(dataStatus).map((item) => (
        <Select.Option key={item}>{dataStatus[item]}</Select.Option>
      ));

      setStatus(arr);
    }
  }, [dataStatus]);

  useEffect(() => {

    handleColumns();

  }, [dataReport]);

  useEffect(() => {
    console.log("flowsData", flowsData);
  }, [flowsData]);

  //handle columns
  async function handleColumns() {
    let newColumns = [];
    const stepTitles = {};
    for (let i = 0; i < dataReport.length; i++) {
      const customer = dataReport[i];
      for (let j = 0; j < customer.service.length; j++) {
        let service = customer.service[j];
        let steps = service.steps;
        if (service.id == filterParams.service) {
          for (let k = 0; k < steps.length; k++) {
            const step = steps[k];
            if (!stepTitles[step.title]) {
              stepTitles[step.title] = true;
            }
          }
        }
      }
    }
    newColumns = [
      {
        title: "Cliente",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        width: 200,
      },
      ...Object.keys(stepTitles).map((title) => ({
        title,
        dataIndex: title,
        key: title,
        width: 150,
        render: (text, record) => {
          const service = record.service.find(
            (service) => service.id == filterParams.service
          );
          if (!service) {
            return null;
          }
          const step = service.steps.find((step) => step.title == title);
          if (!step) {
            return null;
          }
          const status = step.flow.status;
          let tagColor;
          let statusText;
          if (status === "FIN") {
            tagColor = "green";
            statusText = moment(step.flow.end_date).format("DD/MM/YYYY");
          } else if (status === "INP") {
            statusText = moment(step.flow.start_date).format("DD/MM/YYYY");
            tagColor = "yellow";
          } else if (status === "PEN") {
            statusText = moment(step.flow.deadline_date).format("DD/MM/YYYY");
            tagColor = "orange";
          } else if (status === "NOT") {
            tagColor = "grey";
          }
          if (step.flow.deadline_date < moment().format("YYYY-MM-DD") && status != "FIN") {
            tagColor = "red";
            statusText = "Fora do prazo";
          }
          return (
            <div>
              <span>
                <Tag color={tagColor}>
                  <span>
                    <Link to={`/interaction/${step.flow.id}`}>
                      <div style={{ height: 20 }}>{statusText}</div>
                    </Link>
                  </span>
                </Tag>
              </span>
            </div>
          );
        },
      })),
    ];
    setDinamicColums(newColumns);
  }

  //handle service filter
  function handleSeviceFilter(value) {
    setFilterParams({
      ...filterParams,
      service: value
    });
  }

  //handle status filter
  function handleStatusFilter(value) {
    setFilterParams({
      ...filterParams,
      status: value
    });

  }

  useEffect(() => {

    handleColumns();
  }, [dataReport]);

  async function handleFilter() {

    setDataReport(flowsData?.data?.customers);

    if (!dataReport) {
      console.log("dataReport is undefined or null");
      return;
    }

    let filteredData = flowsData?.data?.customers;

    //step 1 = select service by id of all services
    if (filterParams.service) {
      filteredData = await filteredData.filter((item) =>
        item.service.some((service) => service.id == filterParams.service)
      );
    }

    //step 2 = verify if total_steps is equal to total_steps_finished and ignore this data if filterParams fisished is true
    if (filterParams.finished) {
      filteredData = await filteredData.filter((item) =>
        item.service.some((service) => service.total_steps != service.total_steps_finished && service.id == filterParams.service)
      );
    }

    //step 3 = filter status by id of all status
    if (filterParams.status) {
      filteredData = await filteredData.filter((item) =>
        item.service.some((service) =>
          service.steps.some((step) => step.flow.status == filterParams.status && service.id == filterParams.service)
        )
      );
    }

    //step 4 = filter out of time diferent of finished
    if (filterParams.out_of_time) {
      filteredData = filteredData.filter((item) =>
        //select just out of time
        item.service.some((service) =>
          service.steps.some((step) => step.flow.deadline_date < moment().format("YYYY-MM-DD") && step.flow.status != "FIN" && service.id == filterParams.service)
        )
      );
    }

    setDataReport(filteredData);

  }

  //filter data
  async function filter() {
    await form.validateFields();
    await flowsList("flowsList", {});
    setDataReport(flowsData?.data?.customers);
    await handleFilter();
  }

  return (
    <Wrapper>

      <Card className={styled.card}>
        <Form layout="vertical" autoComplete="off" form={form}>
          <Row
            justify="space-between"
            align="center"
            gutter={{ sm: 16, xs: 10 }}
          >
            <Col xs={24} sm={12}>
              <Form.Item label="Serviços" name="services" rules={[{ required: true, message: "Selecione um serviço" }]}>
                <Select

                  size="small"
                  showSearch
                  allowClear
                  placeholder="Selecionar Serviço"
                  optionFilterProp="children"
                  onChange={(value) =>
                    handleSeviceFilter(value)
                  }
                >
                  {serviceData?.data?.map((item) => (
                    <Select.Option key={item.id}>{item.title}</Select.Option>
                  ))}
                  <h1 type="secondary">
                  </h1>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item label="Status" name="status">
                <Select
                  size="small"
                  allowClear
                  showSearch
                  placeholder="Selecionar Status"
                  optionFilterProp="children"
                  onChange={(value) =>
                    handleStatusFilter(value)
                  }
                >
                  {status}
                </Select>

              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7}>
              <Checkbox

                checked={filterParams?.out_of_time}
                onChange={(e) =>
                  setFilterParams({
                    ...filterParams,
                    out_of_time: e.target.checked,
                  })
                }
              >
                Etapa fora do prazo
              </Checkbox>
            </Col>

            <Col xs={24} sm={7}>
              {filterParams.status == null &&

                <Checkbox
                  checked={filterParams?.finished}
                  onChange={(e) =>
                    setFilterParams({
                      ...filterParams,
                      finished: e.target.checked,
                    })
                  }
                >
                  Ignorar concluídos
                </Checkbox>
              }
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                onClick={() => filter()}
              >
                Filtrar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      {
        dataReport &&
        <Card className={styled.card}>
          <Table
            size="small"
            // loading={flowListLoading}
            dataSource={dataReport}
            columns={dinamicColums}
            pagination={false}
            scroll={{ x: 1300 }}
            rowKey={(record) => record?.id}
          />
        </Card>
      }
    </Wrapper>

  );
}

export default Reports;