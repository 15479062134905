// import * as Yup from "yup";

import {
    Alert,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Popconfirm,
    Row,
    Select,
    Spin,
    Table,
    Upload,
    message,
} from "antd";
import { Permissions, hasPermission } from "../../router/permissions";
import { useEffect, useMemo, useState } from "react";

import Attachments from "./attachments";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Messages from "./messages";
import { StatusType } from "./type";
import USStates from "../../util/USStates";
import { UploadOutlined } from "@ant-design/icons";
import Wrapper from "../../components/layout";
import moment from "moment-timezone";
import { showEmbededHTML } from "../../util/ckeditor";
import styled from "./styled.module.scss";
import { useAuth } from "../../providers/auth";
import { useParams } from "react-router-dom";
import { useRest } from "../../services/http";

/* eslint-disable react-hooks/exhaustive-deps */
    
                              
  const FlowInteractionCustomer = () => {
    const [form] = Form.useForm();
    const { user } = useAuth();
    const { id } = useParams();
    const { post: postInteractionMessage, status, loading, data, refresh } = useRest();
    const [fileList, setFileList] = useState([]);
  
    const [optionsInstruction, setOptionsInstruction] = useState([]);
    const [instructionsList, setInstructionsList] = useState([]);
    const [responsables, setResponsables] = useState([]);
  
    const [formValues, setFormValues] = useState({
      is_private: 0,
      flow_id: id,
      notify: 0,
    });
  
    const [flowHandle, setFlowHandle] = useState({});
    const [initialEndDate, setInitialEndDate] = useState(null);
    const [initialStartDate, setInitialStartDate] = useState(null);
    const [initialDeadlineDate, setInitialDeadlineDate] = useState(null);
  
    const updateMessageFormSubmit = async () => {
  

  

      
      // refresh flow data
      await flow("flow", {
        id: id,
      });
  
    };
  
  
    const {
      put: flowUpdate,
      status: flowUpdateStatus,
      loading: flowUpdateLoading,
      data: flowUpdateData,
    } = useRest();
  
    const { get: instructions, data: instructionsData } = useRest();
  
    const {
      get: flow,
      status: flowStatus,
      loading: flowLoading,
      data: flowData,
    } = useRest();
  
    const {
      get: fullInstructionsList,
      loading: fullInstructionsListLoading,
      data: fullInstructionsListData,
    } = useRest();
  
    const {
      post: flowUpdateInstruction,
      status: flowUpdateInstructionStatus,
      message: flowUpdateInstructionMessage,
      loading: flowUpdateInstructionLoading,
    } = useRest();
  
    const {
      remove: removeInstructionFromFlow,
      status: removeInstructionFromFlowStatus,
      message: removeInstructionFromFlowMessage,
    } = useRest();
  
    const { get: fullResponsableList, data: fullDataResponsableList } = useRest();
  
    useEffect(() => {
      if (removeInstructionFromFlowStatus.success) {
        flow("flow", {
          id: id,
        });
        message.success(removeInstructionFromFlowMessage);
      }
      if (removeInstructionFromFlowStatus.error) {
        message.error(removeInstructionFromFlowMessage);
      }
    }, [removeInstructionFromFlowStatus]);
  
    useEffect(() => {
      const data = fullDataResponsableList?.map((item) => (
        <Select.Option key={item.id}>{item.description}</Select.Option>
      ));
      setResponsables(data);
    }, [fullDataResponsableList]);
  
    useEffect(() => {
      if (flowStatus.success) {
        form.setFieldsValue({
            responsavel: flowData?.assignee_id ? String(flowData?.assignee_id) : undefined, // Verifica se é null e define undefined
        });
  
        setFlowHandle({
            ...flowHandle,
            deadline_date: flowData?.deadline_date,
            start_date: flowData?.start_date,
            end_date: flowData?.end_date,
            status: flowData?.status,
        });
    }
    }, [flowData, flowStatus]);
  
    useEffect(() => {
      instructions("instructions", {
        id: id,
      });
      flow("flow", {
        id: id,
      });
      fullResponsableList("fullResponsableList");
      setInstructionsList(instructionsData);
      console.log(instructionsData);
    }, [id]);
  
    useEffect(() => {
      if (!user.isEmployee) {
        setFormValues({ ...formValues, user_id: user.customerID });
      }
    }, [user]);
  
    const submit = () => {
      if (!formValues.message) {
        message.error("Mensagem é obrigatória!");
        return;
      }
  
      const formData = new FormData();
      formData.append("flow_id", formValues.flow_id);
      formData.append("is_private", formValues.is_private);
      formData.append("notify", formValues.notify);
      formData.append("message", formValues.message);
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      postInteractionMessage("postInteractionMessage", formData);
  
  
      // refresh flow data
      flow("flow", {
        id,
      });
  
  
    };
  
    const getResponsableById = (responsableId) => {
      const responsable = fullDataResponsableList?.find(
        (responsable) => responsable.id === parseInt(responsableId)
      );
      return responsable ? responsable.description : ""; // Retorna vazio se null
  };
  
    useEffect(() => {
      if (status?.success) {
        message.success("Mensagem enviada com sucesso!");
        form.resetFields();
        setFileList([]);
        setFormValues({
          is_private: 0,
          flow_id: id,
          notify: 0,
        });
        flow("flow", {
          id: id,
        });
      }
      if (status?.error) {
        data
          ? message.error(Object.values(data)[0])
          : message.error("Ocorreu um erro na criação da mensagem!");
      }
    }, [status, form]);
  
    const props = {
      multiple: true,
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList([...fileList, file]);
        return false;
      },
      onChange(info) {
        const arrayFiles = info.fileList.map((file) =>
          file.originFileObj ? file.originFileObj : file
        );
        setFileList(arrayFiles);
      },
      fileList: fileList,
    };
  
    useEffect(() => {
      fullInstructionsList("fullInstructionsList");
    }, []);
  
    useEffect(() => {
      if (fullInstructionsListData && flowData && flowData.flow_instruction) {
        const existingInstructions = flowData.flow_instruction.map(
          (item) => item.instruction?.id
        ).map(Number);
  
        const newInstructions = fullInstructionsListData.filter(
          (instruction) => !existingInstructions.includes(+instruction.id)
        );
  
        const instructionArr = newInstructions.map((item) => (
          <Select.Option key={item.id}>{item.title}</Select.Option>
        ));
  
        setOptionsInstruction(instructionArr);
      }
    }, [fullInstructionsListData, flowData]);
  
  
    const pushInstruction = async () => {
      const isFormValid = await form.validateFields();
  
      if (isFormValid) {
        const selectedInstructions = form.getFieldValue("instructions") || [];
  
        console.log("selectedInstructions", selectedInstructions);
  
  
        const existingInstructions = flowData?.flow_instruction?.map(
          (item) => item.instruction?.id
        ).map(Number);
  
  
        console.log("existingInstructions", existingInstructions);
  
        const newInstructions = selectedInstructions.filter(
          (instruction) => !existingInstructions.includes(+instruction)
        );
  
        if (newInstructions.length === 0) {
          message.error("Esta instrução já foi adicionada ao fluxo.");
          return;
        }
  
        await flowUpdateInstruction("flowUpdateInstruction", {
          flow_id: id,
          instructions: newInstructions,
        });
      }
    };
  
    useEffect(() => {
      if (flowUpdateInstructionStatus?.success) {
        // message.success(flowUpdateInstructionMessage);
        form.resetFields();
        flow("flow", {
          id: id,
        });
      }
  
      if (flowUpdateInstructionStatus?.error) {
        message.error(flowUpdateInstructionMessage);
      }
    }, [flowUpdateInstructionStatus, form, flowUpdateInstructionMessage]);
  
    const confirm = (id) => {
      removeInstructionFromFlow("removeInstructionFromFlow", { id: id });
    };
  
    const columns = [
      {
        title: "Instruções",
        dataIndex: ["instruction", "title"],
        key: "title",
      },
    ];
  
    user.isEmployee &&
      columns.push({
        title: "Ações",
        dataIndex: "",
        render: (record) => (
          <>
            {hasPermission(user, Permissions.remover_interacoes) && (
              <Popconfirm
                title={
                  "Tem certeza que deseja deletar a instrução?"
                }
                onConfirm={() => confirm(record.id)}
                okText="Confirmar"
                cancelText="Cancelar"
              >
                <a className={styled.icon}>
                  <i
                    className={styled.iconDelete + " ri-delete-bin-fill"}
                    title="Deletar Instrução"
                  />
                </a>
              </Popconfirm>
            )}
          </>
        ),
      });
  
    useEffect(() => {
      if (flowData?.end_date) {
        setInitialEndDate(
          moment(flowData.end_date).tz("America/Sao_Paulo").format("YYYY-MM-DD")
        );
      }
  
      if (flowData?.start_date) {
        setInitialStartDate(
          moment(flowData.start_date)
  
            .tz("America/Sao_Paulo")
            .format("YYYY-MM-DD")
        );
      }
  
      if (flowData?.deadline_date) {
        setInitialDeadlineDate(
          moment(flowData.deadline_date)
            .tz("America/Sao_Paulo")
            .format("YYYY-MM-DD")
        );
      }
  
    }, [flowData]);
  
    const submitFlow = async () => {
      try {
        const values = await form.validateFields();
  
        if (values.end_date === "" || values.end_date === "Invalid date") {
          values.end_date = null;
        }
        if (values.start_date === "" || values.start_date === "Invalid date") {
          values.start_date = null;
        }
        if (values.deadline_date === "" || values.deadline_date === "Invalid date") {
          values.deadline_date = null;
        }
  
        setFlowHandle({ ...flowHandle, ...values });
        await flowUpdate("flow", { id: id }, { ...flowHandle, ...values });
  
        message.success("Fluxo atualizado com sucesso!");
  
        //verificar se houve modificacao do responsavel
        if (flowHandle.assignee_id !== flowData.assignee_id) {
          //se houve modificacao do responsavel, atualizar o responsavel nas mensagens
          flowData?.flow_interaction?.forEach((item) => {
            item.user_id = flowHandle.assignee_id;
            updateMessageFormSubmit();
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const fetchFlowData = useMemo(async () => {
  
      await flow("flow", {
        id: id,
      });
    }, [id])
  
  
    const onUpdate = () => {
      flow("flow", { id: id });
    };

    const getStateName = (stateKey) => {
      const state = USStates.find((s) => s.key === stateKey);
      return state ? state.name : "Não cadastrado.";
    };
  
    return (
      <Wrapper>
  
        <Row align="flex-start" justify="space-between" gutter={[32, 0]}>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
            <Card style={{ marginBottom: 24 }} type="inner" title={flowData?.customer?.user?.name}>
  
              {flowLoading ? (
                <Row type="flex" align="middle" justify="center">
                  <Col>
                    <Spin />
                  </Col>
                </Row>
              ) : (
                <div className={styled.instructions}>
                  {user.isEmployee && (
                    <>
                      <h5>{flowData?.step?.service?.title}</h5>
                      <Divider dashed />
                      <br />
                    </>
                  )}
                  <h4>{flowData?.step?.title}</h4>
                  <h5>{flowData?.step?.description}</h5>
                  <Divider dashed />
                  <br />
  
                  {flowStatus.success &&
                    flowData?.flow_instruction?.length > 0 && (
                      <>
                        <Table
                          className={styled.table}
                          columns={columns}
                          size="small"
                          rowKey={(record) => record?.id}
                          dataSource={flowData?.flow_instruction}
                          pagination={false}
                          loading={flowLoading}
                          //expandir como padrao apenas se for um cliente
                          defaultExpandAllRows={!user.isEmployee}
                          expandable={{
                            expandedRowRender: (record) => (
                              <div className="editorDisabled">
                                <CKEditor
                                  editor={Editor}
                                  data={record.instruction?.description}
                                  disabled={true}
                                />
                              </div>
                            ),
                            onExpand: () => showEmbededHTML(),
                          }}
                        />
                        <br />
                      </>
                    )}
                </div>
              )}
              {fullInstructionsListLoading ? (
                <Row type="flex" align="middle" justify="center">
                  <Col>
                    <Spin />
                  </Col>
                </Row>
              ) : user.isEmployee &&
                hasPermission(user, Permissions.cadastrar_interacoes) ? (
                <Form
                  layout="vertical"
                  name="basic"
                  className="hp-mt-sm-16 hp-mt-16"
                  form={form}
  
                >
                  <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                    <Col xs={24} sm={24} flex="center">
                      <Form.Item
                        name="instructions"
                        label="Instruções"
                      >
                        <Select
                          name="instructions"
                          mode="multiple"
                          size="small"
                          showSearch
                          allowClear
                          placeholder="Selecionar Instruções"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {optionsInstruction}
                        </Select>
                      </Form.Item>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        className={styled.stepButton}
                        loading={flowUpdateInstructionLoading}
                        onClick={pushInstruction}
                      >
                        Adicionar nova instrução
                      </Button>
                    </Col>
                  </Row>
                  <br />
                </Form>
              ) : null}
            </Card>
          </Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
            <Card>
              {(!user.isEmployee && flowData?.status === "FIN") ||
                (!user.isEmployee && flowData?.step?.disable_interactions === 1) ||
                (user.isEmployee &&
                  !hasPermission(user, Permissions.cadastrar_interacoes)) ? null : (
                <div className="ckeditor-min">
                  <Form form={form} layout="vertical">
                    {user.isEmployee ? (
                      <CKEditor
                        data={formValues?.message || ""}
                        editor={Editor}
                        onChange={(events, editor) => {
                          const data = editor.getData();
                          setFormValues({
                            ...formValues,
                            message: data,
                          });
                        }}
                      />
                    ) : (
                      <Form.Item
                        name="mensagem"
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            message: e.target.value,
                          })
                        }
                      >
                        <Input.TextArea placeholder="Adicionar mensagem" />
                      </Form.Item>
                    )}
  
                    <div className={`${styled.editorBottom} ${flowData?.step?.disable_attachment === 1 ? styled.noUpload : ''}`}>
  
                      {(!user.isEmployee && flowData?.step?.disable_attachment === 1) || (
                        <Upload {...props} className={styled.createButton}>
                          <Button icon={<UploadOutlined className="remix-icon" />}>
                            Anexar arquivos
                          </Button>
                        </Upload>
                      )}
  
                      <Button
                        type="primary"
                        onClick={() => submit()}
                        loading={loading}
                        className={styled.sendButton}
                      >
                        Enviar
                      </Button>
  
                    </div>
  
                    {user.isEmployee && (
                      <div className={styled.editorBottom}>
                        <Checkbox
                          className={styled.checkbox}
                          checked={formValues?.notify}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              notify: e.target.checked ? 1 : 0,
                            })
                          }
                        >
                          Notificar cliente
                        </Checkbox>
  
                        <Checkbox
                          className={styled.checkbox}
                          checked={formValues?.is_private}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              is_private: e.target.checked ? 1 : 0,
                            })
                          }
                        >
                          Mensagem privada?
                        </Checkbox>
  
  
  
  
  
                      </div>
                    )}
                  </Form>
                </div>
              )}
  
  
              {hasPermission(user, Permissions.visualizar_interacoes) && (
  
                <Messages
  
                  update={flowData?.flow_interaction}
                  onUpdate={onUpdate}
                  flowStatus={flowData?.status}
                />
  
              )}
            </Card>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
            <Card>
              {flowLoading ? (
                <Row type="flex" align="middle" justify="center">
                  <Col>
                    <Spin />
                  </Col>
                </Row>
              ) : (
                <>
                  {!user.isEmployee && (
                    <>
                      <h5>Responsável</h5>
                      <p>
                        {getResponsableById(flowData?.assignee_id) || "-"} 
                      </p>
  
                      <h5>Status</h5>
                      <p>{StatusType[flowData?.status]}</p>
  
                      <h5>Data de início</h5>
                      <p>
                        {flowData?.start_date
                          ? moment(flowData?.start_date)
                            .tz("America/Sao_Paulo")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </p>
  
                      <h5>Data de conclusão</h5>
                      <p>
                        {flowData?.end_date
                          ? moment(flowData?.end_date)
                            .tz("America/Sao_Paulo")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </p>
  
                      <h5>Board of Nursing</h5>
                      <p>
                        {getStateName(flowData?.state_bon) || "-"}
                      </p>
                    </>
                  )}
                  {flowData?.status && user.isEmployee && (
                    <Form layout="vertical" name="basic" form={form}>
                      <Form.Item label="Responsável" name="responsavel">
                        <Select
                          size="small"
                          showSearch
                          allowClear
                          disabled={
                            !hasPermission(user, Permissions.cadastrar_fluxos)
                          }
                          placeholder="Responsável"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value) =>
                            setFlowHandle({
                                ...flowHandle,
                                assignee_id: value || null, // Define null se o valor for undefined
                            })
                        }
                        >
                          {responsables}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Status"
                        name="status"
                        initialValue={flowData?.status}
                      >
                        <Select
                          size="small"
                          showSearch
                          disabled={
                            !hasPermission(user, Permissions.cadastrar_fluxos)
                          }
                          placeholder="Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value) => {
                            setFlowHandle({ ...flowHandle, status: value });
                          }}
                        >
                          <Select.Option key="NOT">Não Iniciado</Select.Option>
                          <Select.Option key="INP">Em Andamento</Select.Option>
                          <Select.Option key="FIN">Finalizado</Select.Option>
                          <Select.Option key="PEN">Pendente</Select.Option>
                        </Select>
                      </Form.Item>
  
                      <Form.Item
                        label="Prazo"
                        name="deadline_date"
                        initialValue={moment(flowData?.deadline_date)
                          .tz("America/Sao_Paulo")
                          .format("YYYY-MM-DD")}
                        onChange={(e) =>
                          setFlowHandle({
                            ...flowHandle,
                            deadline_date: e.target.value,
                          })
                        }
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const status = getFieldValue("status");
                              if (status === "PEN" && (!value || value === "Invalid date")) {
                                return Promise.reject("Insira o prazo.");
                              }
                              return Promise.resolve();
                            },
                            dependencies: ["status"],
                          }),
                        ]}
                      >
                        <Input
                          size="small"
                          type="date"
                          disabled={
                            !hasPermission(user, Permissions.cadastrar_fluxos)
                          }
                        />
                      </Form.Item>
  
                      <Form.Item
                        label="Data de início"
                        name="start_date"
                        initialValue={moment(flowData?.start_date)
                          .tz("America/Sao_Paulo")
                          .format("YYYY-MM-DD")}
                        onChange={(e) =>
                          setFlowHandle({
                            ...flowHandle,
                            start_date: e.target.value,
                          })
                        }
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const status = getFieldValue("status");
                              if ((status === "PEN" || status === "FIN" || status === "INP") && (!value || value === "Invalid date")) {
                                return Promise.reject("Insira a data de início");
                              }
                              return Promise.resolve();
                            },
                            dependencies: ["status"],
                          }),
                        ]}
                      >
                        <Input
                          size="small"
                          type="date"
                          disabled={
                            !hasPermission(user, Permissions.cadastrar_fluxos)
                          }
                        />
                      </Form.Item>
  
                      <Form.Item
                        label="Data de conclusão"
                        name="end_date"
                        initialValue={moment(flowData?.end_date)
                          .tz("America/Sao_Paulo")
                          .format("YYYY-MM-DD")}
                        onChange={(e) =>
                          setFlowHandle({
                            ...flowHandle,
                            end_date: e.target.value,
                          })
                        }
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const status = getFieldValue("status");
                              if (status === "FIN" && (!value || value === "Invalid date")) {
                                return Promise.reject("Insira a data de conclusão!");
                              }
                              return Promise.resolve();
                            },
                            dependencies: ["status"],
                          }),
                        ]}
                      >
                        <Input
                          size="small"
                          type="date"
                          disabled={
                            !hasPermission(user, Permissions.cadastrar_fluxos)
                          }
                        />
                      </Form.Item>
  
                      <Form.Item
                        label="Board of Nursing"
                        name="state_bon"
                        initialValue={flowData?.state_bon}
                      >
                        <Select
                          size="small"
                          showSearch
                          allowClear
                          placeholder="Selecionar Estado"
                          optionFilterProp="children"
                          onChange={(value) =>
                            setFlowHandle({
                              ...flowHandle,
                              state_bon: value,
                            })
                          }
                          disabled={!hasPermission(user, Permissions.cadastrar_fluxos)}
                        >
                          {USStates.map((state) => (
                            <Select.Option key={state.key} value={state.key}>
                              {state.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
  
                      <Button
                        type="primary"
                        disabled={
                          !hasPermission(user, Permissions.cadastrar_fluxos)
                        }
                        className={styled.createButton}
                        loading={flowUpdateLoading}
                        onClick={() => submitFlow()}
                      >
                        Salvar
                      </Button>
                    </Form>
                  )}
  
  
                  <Attachments update={fetchFlowData} onUpdate={onUpdate} flowData={flowData} />
  
  
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Wrapper>
    );
  };
  
  export default FlowInteractionCustomer;
  